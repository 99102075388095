@import 'styles/variable.scss';

.createShowcaseContainer {
    max-width: 920px;
    margin: 0 auto;
}

.showcaseStep {
    margin-bottom: $space-10;
}

.footerButtons {
    height: 40px;
    width: 240px;
}