@import 'styles/variable.scss';

.numberColumnTitle {
    display: flex;
    flex-direction: row-reverse;
}

.tableCell {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.labelTable {
    padding-bottom: $space-2;
}

.statisticTable {
    margin-bottom: $space-6;
}