@import 'styles/variable.scss';

.userIdentityContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 0 !important;
    padding: 0 !important;

    .userIdentity .name {
        color: $neutral-1;
    }
}