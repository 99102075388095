@import 'styles/variable.scss';

$search-showcase-width: 330px;
$search-showcase-height: $space-10;

.tabContainer {
    :global(.ant-tabs-nav) {
        margin-bottom: 0;

        &::before {
            display: none;
        }

        :global(.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab) {
            border-radius: 0;
            border: none;
            background: transparent;
            height: $space-13;
            color: $neutral-6;

            &:global(.ant-tabs-tab-active) {
                background-color: $neutral-1;
            }
        }
    }
}

.resultQueryContainer {
    padding: 0 $space-4 $space-2 $space-4;
    background-color: $neutral-1;

    .resultQuery {
        padding: $space-1 0;
        display: inline-block;
        color: $neutral-6;
    }
}

.searchForm {
    :global(.ant-form-item) {
        margin-bottom: 0;
    }

    .searchInput {
        width: $search-showcase-width;
        height: $search-showcase-height;
    }
}

.tableCellFlex {
    display: flex;
    flex-flow: row wrap;
    gap: $space-1;
}

.emptyShowcaseContainer {
    width: 100%;
    padding: $space-13 0;

    .titleEmpty {
        margin-bottom: 0;
    }
}

.actionContainer {
    display: flex;
    flex-flow: row nowrap;

    .actionBtn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}