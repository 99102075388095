@import 'styles/variable.scss';

.inputContainer {
    width: $space-15;
    border-radius: 0;

    :global(.ant-input-number-input) {
        text-align: right;
        padding-right: 0;
    }

    :global(.ant-input-number-group-addon) {
        background: transparent;
        border: none;
        padding: 0;
    }
}