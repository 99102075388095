@import 'styles/variable.scss';

.numberColumnTitle {
    display: flex;
    flex-direction: row-reverse;
}

.inputNumberContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
}

.expertiseScopeContainer {
    .expertiseScopeItem {
        border-bottom: 1px solid $neutral-3;

        &:last-child {
            border-radius: 0px;
        }

        .labelLinkApp {
            margin-bottom: $space-2;
        }

        .linkContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: $space-2 $space-6;
            margin-bottom: $space-2;

            .linkItem {
                margin-bottom: 0;
            }
        }

        .inputLink {
            color: $blue-6;
        }
    }
}


.addMoreBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space-2;
    margin-bottom: $space-5;

    font-weight: 500;
    color: $primary-color;
}

.addExpertiseScopeBtn {
    margin-top: $space-4;
}

.addExpertiseModalContainer {
    .listExpertise {
        display: flex;
        flex-flow: column;
        gap: $space-2;
        padding: $space-6 0;

        border-top: 1px solid $neutral-3;
        border-bottom: 1px solid $neutral-3;
    }
}

.statisticTable {
    margin-bottom: $space-6;
}