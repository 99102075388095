@import './fonts/fonts.css';

:root {
    --teal-1: #e3fffb;
    --teal-2: #c3fcf4;
    --teal-3: #98f4eb;
    --teal-4: #5cdbd3;
    --teal-5: #33b3aa;
    --teal-6: #089897;
    --teal-7: #0b7b7a;
    --teal-8: #005c5c;
    --teal-9: #004040;
    --teal-10: #002329;

    --neutral-1: #ffffff;
    --neutral-2: #f5f6fa;
    --neutral-3: #ebecf0;
    --neutral-4: #d4d5d9;
    --neutral-5: #bbbcbf;
    --neutral-6: #8a8a8c;
    --neutral-7: #575859;
    --neutral-8: #414142;
    --neutral-9: #2a2a2b;
    --neutral-10: #000d0b;

    --blue-6: #096dd9;
    --red-5: #f5222d;

    --space-1: 4px;
    --space-2: 8px;
    --space-3: 12px;
    --space-4: 16px;
    --space-5: 20px;
    --space-6: 24px;
    --space-7: 28px;
    --space-8: 32px;
    --space-9: 36px;
    --space-10: 40px;
    --space-11: 44px;
    --space-12: 48px;
    --space-13: 52px;
    --space-14: 56px;
    --space-15: 60px;
    --space-16: 64px;
    --space-17: 68px;
    --space-18: 72px;
    --space-19: 76px;
    --space-20: 80px;
}
