@import 'styles/variable.scss';

.numberColumnTitle {
    display: flex;
    flex-direction: row-reverse;
}

.inputNumberContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
}

.totalRowText {
    float: right;
}

.labelTable {
    padding-bottom: $space-2;
}

.statisticTable {
    margin-bottom: $space-6;
}