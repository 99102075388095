/* Semi Bold */
@font-face {
    font-family: 'SF Pro Display';
    font-weight: 600; 
    font-style: normal;
    src: url('/public/SanFranciscoPro/SF-Pro-Display-Semibold.otf');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 600; 
    font-style: italic;
    src: url('/public/SanFranciscoPro/SF-Pro-Display-SemiboldItalic.otf');
}


/* Medium */
@font-face {
    font-family: 'SF Pro Display';
    font-weight: 500; 
    font-style: normal;
    src: url('/public/SanFranciscoPro/SF-Pro-Display-Medium.otf');
}
@font-face {
    font-family: 'SF Pro Display';
    font-weight: 500; 
    font-style: italic;
    src: url('/public/SanFranciscoPro/SF-Pro-Display-MediumItalic.otf');
}

/* Regular */
@font-face {
    font-family: 'SF Pro Display';
    font-weight: 400; 
    font-style: normal;
    src: url('/public/SanFranciscoPro/SF-Pro-Display-Regular.otf');
}
@font-face {
    font-family: 'SF Pro Display';
    font-weight: 400; 
    font-style: italic;
    src: url('/public/SanFranciscoPro/SF-Pro-Display-RegularItalic.otf');
}