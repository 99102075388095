@import 'styles/variable.scss';

$horizontal-tag-padding: 10px;

.tagFieldContainer {

    :global(.ant-tag) {
        font-size: $font-size-sm;
        border-radius: $space-4;

        padding-left: $horizontal-tag-padding;
        padding-right: $horizontal-tag-padding;
        margin-right: 0;
    }
}