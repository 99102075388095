@import 'styles/variable.scss';

.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    min-height: 600px;
    background-image: url("../../../public/login.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.loginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-10 $space-13;
    background-color: #fff;
    border-radius: $border-radius-base;

    :global {
        img {
            margin-bottom: $space-8;
        }

        h3 {
            margin-bottom: $space-10;
            font-weight: bold;
            font-size: $space-6;
            line-height: $space-8;
        }

        .ant-btn {
            width: 345px;
        }
    }
}