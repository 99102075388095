@import 'styles/variable.scss';

.appearTagContainer {
    padding-left: $space-4;

    .appearTagList {
        display: flex;
        flex-direction: column;
        gap: $space-2;

        label {
            margin-inline-start: 0;
            width: fit-content;
        }

        .appearTagItem {
            padding: $space-2 0;
        }
    }
}