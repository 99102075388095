@import 'styles/variable.scss';

.draggerContainer {

    :global(.ant-upload-list) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: $space-6;

        &::before {
            display: none;
        }

        :global(.ant-upload-list-item-container) {
            overflow: hidden;
            color: $primary-color;
        }
    }
}